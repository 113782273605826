import { Identify, identify, setUserId } from '@amplitude/analytics-browser';
import { addHeadingZeros } from '@Utils/dates';
import { getUserLanguage } from '@Utils/getUserLanguage';
import { getTelegramUserInfo } from '@Utils/telegram';
import { Viewer } from '@Global/apollo/types';

/**
 * Сеттим то, что будет содержаться в личности пользователя при каждом отправке ивента в Amplitude
 * @param {object} [viewer] - Объект пользователя
 * @returns {void}
 **/

export const IdentifyUser = (viewer: Viewer) => {
  const registeredAt = viewer.registeredAt;
  const startToken = viewer.startToken;
  const user = getTelegramUserInfo();

  if (!user) {
    return;
  }

  const userIdentity = new Identify();

  userIdentity.setOnce('id', user.id);
  userIdentity.set('first_name', user.first_name);
  user.last_name && userIdentity.set('last_name', user.last_name);
  user.username && userIdentity.set('username', user.username);
  userIdentity.set('language_code', getUserLanguage());
  userIdentity.set('auth_date', user.auth_date);
  userIdentity.set('features', viewer.features);

  let registeredAtDate = new Date();

  if (registeredAt) {
    registeredAtDate = new Date(registeredAt);
  }

  if (startToken) {
    userIdentity.set('start_token', startToken);
  }

  userIdentity.set('new_user', (Date.now() - registeredAtDate.getTime()) / 60000 <= 60);

  userIdentity.setOnce('registered_at_unix', Number(registeredAtDate.getTime() / 1000)); // в секундах
  userIdentity.setOnce(
    'registered_at_date',
    Number(
      registeredAtDate.getFullYear().toString() +
        addHeadingZeros(registeredAtDate.getMonth() + 1, 2) +
        addHeadingZeros(registeredAtDate.getDate(), 2),
    ),
  );

  setUserId(user.id.toString());
  identify(userIdentity);
};
