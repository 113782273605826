export const addMatchMediaSupport = () => {
  window.origMatchMedia = matchMedia;
  window.matchMedia = (mediaQueryString: string) => {
    const mm = window.origMatchMedia(mediaQueryString);
    if (typeof mm.addEventListener === 'undefined') {
      mm.addEventListener = (_: string, cb: any) => {
        mm.addListener(cb);
      };
    }

    return mm;
  };
};
