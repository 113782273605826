import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { allGamesFiltersReducer } from './slices/allGamesFilterSlice';
import { homeScreenAllGamesFiltersReducer } from './slices/homeAllGamesFilterSlice';
import { myGamesFiltersReducer } from './slices/myGamesFiltersSlice';
import { pvpAllGamesFiltersReducer } from './slices/pvpAllGamesFiltersSlice';
import { pvpMyGamesFiltersReducer } from './slices/pvpMyGamesFiltersSlice';

export const store = configureStore({
  reducer: {
    homeScreenAllGamesFilters: homeScreenAllGamesFiltersReducer,
    pvpAllGamesFilters: pvpAllGamesFiltersReducer,
    pvpMyGamesFilters: pvpMyGamesFiltersReducer,
    myGamesFilters: myGamesFiltersReducer,
    allGamesFilters: allGamesFiltersReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
