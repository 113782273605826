import { useContext, useEffect } from 'react';
import { AppContext } from '@Global/store/appContext';

export const useAddBeforeUnloadPromptEffect = () => {
  const { appEnv } = useContext(AppContext);

  useEffect(() => {
    if (appEnv === 'telegram') {
      window.Telegram.WebApp.enableClosingConfirmation();
    }
  }, [appEnv]);
};
