/** получение параметров телеграмм окружения */
export function getInitDataSource() {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return process.env.REACT_APP_QUERY_ID;
    case 'qa':
      return process.env.REACT_APP_QA_QUERY_ID;
    case 'prod':
      return process.env.REACT_APP_PROD_QUERY_ID;
    default:
      return process.env.REACT_APP_TELEGRAM_INIT_DATA;
  }
}
