/**
 * Парсими данные пользователя из window.Telegram
 * @returns {(Object | null)} - Объект с данными пользователя, null если данные не доступны
 */
export const getTelegramUserInfo = () => {
  // TODO: validate user data with initData hash (https://core.telegram.org/bots/webapps#validating-data-received-via-the-web-app)

  const { user, auth_date } = window.Telegram.WebApp.initDataUnsafe;

  if (!user || !user.id || !user.first_name) {
    return null;
  }

  const { id, first_name, last_name, username, language_code } = user;

  return {
    id,
    first_name,
    last_name,
    username,
    language_code,
    auth_date,
  };
};
