import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Game, Viewer } from '@Global/apollo/types';
import { ViewerContext } from '@Global/store';

export enum EUserGameStatus {
  GET_PRIZE = 'GET_PRIZE',
  PAID = 'PAID',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  JOIN = 'JOIN',
  TIMER = 'TIMER',
}

export const userIsParticipant = (game: Game): boolean => {
  return Boolean(game.myTeams.length);
};

export const getUserGameStatus = (game: Game, viewer: Viewer): EUserGameStatus => {
  const from = game.period.from;
  const isStarted = game.isStarted;
  const isCompleted = game.isCompleted;
  const hasTicket = viewer?.gameTickets?.includes(game?.id);
  const isPart = userIsParticipant(game);
  const isStartedByTime = moment().isAfter(moment(from).utc());
  const hasPrize = game.myTeams.some((team) => team.giftsLeft == null || team.giftsLeft > 0);

  const isStartedByAnyData = isStarted || isStartedByTime;

  if (hasPrize && isCompleted) {
    return EUserGameStatus.GET_PRIZE;
  } else if (hasTicket && !isPart && !isCompleted) {
    return EUserGameStatus.PAID;
  } else if (!isPart && !isStartedByAnyData) {
    return EUserGameStatus.JOIN;
  } else if (isPart && !isStartedByAnyData) {
    return EUserGameStatus.TIMER;
  } else if (isStartedByAnyData && !isCompleted) {
    return EUserGameStatus.IN_PROGRESS;
  }

  return EUserGameStatus.COMPLETED;
};

export const useUserGameStatus = (game: Game): EUserGameStatus => {
  const { viewer } = useContext(ViewerContext);
  const [isTimerPast, setIsTimerPast] = useState<boolean>(false);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const status = useMemo(() => {
    if (viewer) {
      return getUserGameStatus(game, viewer);
    }

    return EUserGameStatus.JOIN;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewer, game, isTimerPast]);

  useEffect(() => {
    if (status === EUserGameStatus.TIMER) {
      timeout.current = setTimeout(() => {
        setIsTimerPast(true);
      }, Date.now() - game.period.to);
    }
    return () => clearTimeout(timeout.current);
  }, [game.period.to, status]);

  return status;
};
