import React from 'react';
import icon from './assets/logo.svg';

/** Логотип */
export const Logo = ({ width, height }) => {
  return (
    <div className="logo" style={{ width, height }}>
      <img width={width} height={height} alt="" src={icon} />
    </div>
  );
};
