export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  MoneyAmount: any;
  Time: any;
  URL: any;
  Void: any;
};

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  token: Scalars['String'];
  viewer: Viewer;
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  crypto?: Maybe<CryptoDetails>;
  links: Array<PaymentLink>;
};

export type Card = Node & {
  __typename?: 'Card';
  id: Scalars['ID'];
  image?: Maybe<Scalars['URL']>;
  isAtAuction: Scalars['Boolean'];
  isOnSale: Scalars['Boolean'];
  links: Array<CardLink>;
  multiplier: Scalars['Float'];
  player: Player;
  price?: Maybe<Money>;
  rarity: Rarity;
  tenancy?: Maybe<CardTenancy>;
  tier: Scalars['Int'];
};

export type CardConnection = {
  __typename?: 'CardConnection';
  edges: Array<CardEdge>;
  pageInfo: PageInfo;
};

export type CardDistributionRule = {
  __typename?: 'CardDistributionRule';
  cardFilter: CardFilter;
  odds: Scalars['Int'];
};

export type CardEdge = {
  __typename?: 'CardEdge';
  cursor: Scalars['Cursor'];
  node: Card;
};

export type CardFilter = {
  __typename?: 'CardFilter';
  positions?: Maybe<Array<PlayerPosition>>;
  rarities?: Maybe<Array<Rarity>>;
  teams?: Maybe<Array<Team>>;
  tiers?: Maybe<Array<Scalars['Int']>>;
  tournaments?: Maybe<Array<Tournament>>;
};

export type CardLink = {
  __typename?: 'CardLink';
  provider: CardLinkProvider;
  url: Scalars['String'];
};

export enum CardLinkProvider {
  Getgems = 'GETGEMS'
}

export enum CardOrder {
  Default = 'DEFAULT',
  PriceAsc = 'PRICE_ASC',
  PriceDsc = 'PRICE_DSC'
}

export type CardRestriction = {
  __typename?: 'CardRestriction';
  maxCount?: Maybe<Scalars['Int']>;
  minCount?: Maybe<Scalars['Int']>;
  rarities?: Maybe<Array<Rarity>>;
};

export type CardTenancy = {
  __typename?: 'CardTenancy';
  gamesLeft: Scalars['Int'];
};

export type CardValues = {
  __typename?: 'CardValues';
  citizenship: Array<Scalars['String']>;
  positions: Array<PlayerPosition>;
  rarities: Array<Rarity>;
  teams: Array<Team>;
  tiers: Array<Scalars['Int']>;
  tournaments: Array<Tournament>;
};

export type CommonPack = Node & {
  __typename?: 'CommonPack';
  cardFilter: CardFilter;
  createdAt: Scalars['Time'];
  expiresAt?: Maybe<Scalars['Time']>;
  game?: Maybe<Game>;
  id: Scalars['ID'];
  isClaimed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  userTeamId?: Maybe<Scalars['ID']>;
};


export type CommonPackNameArgs = {
  locale?: InputMaybe<Locale>;
};

export type CryptoDetails = {
  __typename?: 'CryptoDetails';
  address: Scalars['String'];
  amount: Scalars['MoneyAmount'];
  message?: Maybe<Scalars['String']>;
};

export enum Currency {
  Aed = 'AED',
  Btc = 'BTC',
  Eth = 'ETH',
  Eur = 'EUR',
  Fntf = 'FNTF',
  Fton = 'FTON',
  Gbp = 'GBP',
  Jetton = 'JETTON',
  Not = 'NOT',
  Rub = 'RUB',
  Ston = 'STON',
  Tcn = 'TCN',
  Testtusd = 'TESTTUSD',
  Ton = 'TON',
  Usd = 'USD',
  Usdt = 'USDT',
  Usdterc20 = 'USDTERC20',
  Usdttrc20 = 'USDTTRC20',
  Xtr = 'XTR'
}

export type DateInterval = {
  __typename?: 'DateInterval';
  from: Scalars['Time'];
  to: Scalars['Time'];
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  amount: Scalars['MoneyAmount'];
  source: Currency;
  target: Currency;
};

export type Game = Node & {
  __typename?: 'Game';
  completedAt?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  features: Array<Scalars['String']>;
  giftRuleSystemId?: Maybe<Scalars['String']>;
  guarantee?: Maybe<Money>;
  highlightColor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  isCompleted: Scalars['Boolean'];
  isCreatedByMe: Scalars['Boolean'];
  isMainEvent: Scalars['Boolean'];
  isMultiteam: Scalars['Boolean'];
  isNft?: Maybe<Scalars['Boolean']>;
  isOneMatchGame: Scalars['Boolean'];
  isPaid: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  isPvP: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  labels: Array<Scalars['String']>;
  matchTeams: Array<Team>;
  matches: Array<Match>;
  myGifts: Array<Gift>;
  myTasks: Array<GameTask>;
  myTeams: Array<UserTeam>;
  name: Scalars['String'];
  numberOfTeams?: Maybe<Scalars['Int']>;
  period: DateInterval;
  playersOnTheSameTeamLimit: Scalars['Int'];
  prototypeGameId?: Maybe<Scalars['ID']>;
  rake?: Maybe<Scalars['Float']>;
  requirements?: Maybe<GameRequirements>;
  scoringSystemVersion: Scalars['Int'];
  suggestedCommonPacks: Array<CommonPack>;
  teams: UserTeamConnection;
  teamsPerUserLimit: Scalars['Int'];
  ticketPrice?: Maybe<Money>;
  tournamentTeams: Array<TournamentTeam>;
  tournaments: Array<Tournament>;
};


export type GameDescriptionArgs = {
  locale?: InputMaybe<Locale>;
};


export type GameNameArgs = {
  locale?: InputMaybe<Locale>;
};


export type GameTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  groupIndex?: InputMaybe<Scalars['Int']>;
};

export type GameConnection = {
  __typename?: 'GameConnection';
  edges: Array<GameEdge>;
  pageInfo: PageInfo;
};

export type GameEdge = {
  __typename?: 'GameEdge';
  cursor: Scalars['Cursor'];
  node: Game;
};

export type GameFilter = {
  __typename?: 'GameFilter';
  isNft?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Scalars['String']>>;
};

export enum GameLifecycleStage {
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  Ongoing = 'ONGOING',
  Upcoming = 'UPCOMING'
}

export type GameLifecycleStageIntKeyValueItem = {
  __typename?: 'GameLifecycleStageIntKeyValueItem';
  key?: Maybe<GameLifecycleStage>;
  val: Scalars['Int'];
};

export type GameRequirements = {
  __typename?: 'GameRequirements';
  cardRestrictions: Array<CardRestriction>;
  id: Scalars['ID'];
};

export type GameTask = {
  __typename?: 'GameTask';
  checkpoint: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type GameTaskNameArgs = {
  locale?: InputMaybe<Locale>;
};

export type GameValues = {
  __typename?: 'GameValues';
  labels: Array<IntKeyValueItem>;
  lifecycleStages: Array<GameLifecycleStageIntKeyValueItem>;
  ticketPrices: Array<MoneyIntKeyValueItem>;
};

export type Gift = CommonPack | MoneyReward | NftCardGiveaway | SeasonTicketReward;

export type GiftConnection = {
  __typename?: 'GiftConnection';
  edges: Array<GiftEdge>;
  pageInfo: PageInfo;
};

export type GiftEdge = {
  __typename?: 'GiftEdge';
  cursor: Scalars['Cursor'];
  node: Gift;
};

export type IntKeyValueItem = {
  __typename?: 'IntKeyValueItem';
  key?: Maybe<Scalars['String']>;
  val: Scalars['Int'];
};

export type IntRangeInput = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type InviteeEventSummary = {
  __typename?: 'InviteeEventSummary';
  playedFreeGame: Scalars['Int'];
  playedPaidGame: Scalars['Int'];
};

export type InviterStats = {
  __typename?: 'InviterStats';
  inviteeEventSummary: InviteeEventSummary;
  numberOfInvitees: Scalars['Int'];
  seasonTicketSummary: SeasonTicketSummary;
};

export type LeaderboardChallenge = Node & {
  __typename?: 'LeaderboardChallenge';
  giftRuleSystemId: Scalars['ID'];
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  isStarted: Scalars['Boolean'];
  myGifts: Array<Gift>;
  myPosition: LeaderboardParticipant;
  name: Scalars['String'];
  numberOfParticipants: Scalars['Int'];
  participants: LeaderboardParticipantConnection;
  period: DateInterval;
  prizePool?: Maybe<Money>;
  scoringSystemVersion: Scalars['Int'];
};


export type LeaderboardChallengeNameArgs = {
  locale?: InputMaybe<Locale>;
};


export type LeaderboardChallengeParticipantsArgs = {
  accounts?: InputMaybe<Array<Scalars['ID']>>;
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type LeaderboardChallengeConnection = {
  __typename?: 'LeaderboardChallengeConnection';
  edges: Array<LeaderboardChallengeEdge>;
  pageInfo: PageInfo;
};

export type LeaderboardChallengeEdge = {
  __typename?: 'LeaderboardChallengeEdge';
  cursor: Scalars['Cursor'];
  node: LeaderboardChallenge;
};

export enum LeaderboardLevel {
  WorldClub = 'WORLD_CLUB'
}

export type LeaderboardParticipant = Node & {
  __typename?: 'LeaderboardParticipant';
  account: UserAccount;
  challenge: LeaderboardChallenge;
  giftsLeft?: Maybe<Scalars['Int']>;
  history: UserTeamConnection;
  id: Scalars['ID'];
  level?: Maybe<LeaderboardLevel>;
  place?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type LeaderboardParticipantHistoryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type LeaderboardParticipantConnection = {
  __typename?: 'LeaderboardParticipantConnection';
  edges: Array<LeaderboardParticipantEdge>;
  pageInfo: PageInfo;
};

export type LeaderboardParticipantEdge = {
  __typename?: 'LeaderboardParticipantEdge';
  cursor: Scalars['Cursor'];
  node: LeaderboardParticipant;
};

export enum Locale {
  En = 'EN',
  Ru = 'RU'
}

export type LootBoxOffer = Node & {
  __typename?: 'LootBoxOffer';
  distributionRules: Array<CardDistributionRule>;
  expiresAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  itemsLeft: Scalars['Int'];
  name: Scalars['String'];
  numberOfItems: Scalars['Int'];
  price: Money;
  valueClass: LootBoxValueClass;
};


export type LootBoxOfferNameArgs = {
  locale?: InputMaybe<Locale>;
};

export type LootBoxOfferConnection = {
  __typename?: 'LootBoxOfferConnection';
  edges: Array<LootBoxOfferEdge>;
  pageInfo: PageInfo;
};

export type LootBoxOfferEdge = {
  __typename?: 'LootBoxOfferEdge';
  cursor: Scalars['Cursor'];
  node: LootBoxOffer;
};

export type LootBoxPurchase = Node & {
  __typename?: 'LootBoxPurchase';
  cards: Array<Card>;
  id: Scalars['ID'];
};

export enum LootBoxValueClass {
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Silver = 'SILVER'
}

export type Match = Node & {
  __typename?: 'Match';
  awayTeam: Team;
  caption?: Maybe<Scalars['String']>;
  homeTeam: Team;
  id: Scalars['ID'];
  matchDay: Scalars['Time'];
  playerStats: Array<PlayerStatValue>;
  score?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Time']>;
  status: MatchStatus;
  tournament: Tournament;
};


export type MatchPlayerStatsArgs = {
  players?: InputMaybe<Array<Scalars['ID']>>;
};

export type MatchConnection = {
  __typename?: 'MatchConnection';
  edges: Array<MatchEdge>;
  pageInfo: PageInfo;
};

export type MatchEdge = {
  __typename?: 'MatchEdge';
  cursor: Scalars['Cursor'];
  node: Match;
};

export type MatchEvent = Node & {
  __typename?: 'MatchEvent';
  id: Scalars['ID'];
  match: Match;
  subtype?: Maybe<Scalars['String']>;
  time: MatchTime;
  type: MatchEventType;
};

export enum MatchEventType {
  Card = 'CARD',
  End = 'END',
  Goal = 'GOAL',
  KickOff = 'KICK_OFF',
  MissedPenalty = 'MISSED_PENALTY',
  Substitution = 'SUBSTITUTION'
}

export enum MatchStatus {
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  Live = 'LIVE',
  Postponed = 'POSTPONED',
  Scheduled = 'SCHEDULED',
  Undefined = 'UNDEFINED'
}

export type MatchTime = {
  __typename?: 'MatchTime';
  extra?: Maybe<Scalars['Int']>;
  normal: Scalars['Int'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Currency;
};

export type MoneyInput = {
  amount: Scalars['MoneyAmount'];
  currency: Currency;
};

export type MoneyIntKeyValueItem = {
  __typename?: 'MoneyIntKeyValueItem';
  key?: Maybe<Money>;
  val: Scalars['Int'];
};

export type MoneyRangeInput = {
  max?: InputMaybe<MoneyInput>;
  min?: InputMaybe<MoneyInput>;
};

export type MoneyReward = Node & {
  __typename?: 'MoneyReward';
  createdAt: Scalars['Time'];
  expiresAt?: Maybe<Scalars['Time']>;
  game?: Maybe<Game>;
  id: Scalars['ID'];
  isClaimed: Scalars['Boolean'];
  rewardSum: Money;
  userTeamId?: Maybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  buyLootBoxItem: LootBoxPurchase;
  checkGameTaskCheckpointPassed: Array<GameTask>;
  claimCommonPack?: Maybe<Scalars['Void']>;
  claimMoneyReward?: Maybe<Scalars['Void']>;
  claimNftCard: Gift;
  claimSeasonTicketReward: Gift;
  commitOnboardingStep?: Maybe<Scalars['Void']>;
  createBillingDetails: BillingDetails;
  createNeoCryptoGameTicketPaymentLink: Scalars['String'];
  createTelegramStarsInvoiceLink: Scalars['String'];
  createTonkeeperGameTicketPaymentLink: Scalars['String'];
  generateTonConnectPayload: Scalars['String'];
  handleNeoCryptoPayment?: Maybe<Scalars['Void']>;
  sendTelegramStarsInvoice?: Maybe<Scalars['Void']>;
  signInViaTonConnect: AuthenticationResult;
  signOutViaTonConnect?: Maybe<Scalars['Void']>;
  stakeTokens?: Maybe<Scalars['Void']>;
  submitGame: Game;
  submitTeam: UserTeam;
  swapTokens?: Maybe<Scalars['Void']>;
  withdrawFromWallet?: Maybe<Scalars['Void']>;
  yieldGiftPack: Array<Gift>;
  yieldGiftPackForLeaderboardChallenge: Array<Gift>;
};


export type MutationBuyLootBoxItemArgs = {
  lootBoxOfferId: Scalars['ID'];
};


export type MutationCheckGameTaskCheckpointPassedArgs = {
  checkpoint: Scalars['String'];
  gameId: Scalars['ID'];
};


export type MutationClaimCommonPackArgs = {
  cardIds: Array<Scalars['ID']>;
  giftId: Scalars['ID'];
};


export type MutationClaimMoneyRewardArgs = {
  giftId: Scalars['ID'];
};


export type MutationClaimNftCardArgs = {
  giftId: Scalars['ID'];
};


export type MutationClaimSeasonTicketRewardArgs = {
  giftId: Scalars['ID'];
};


export type MutationCommitOnboardingStepArgs = {
  step: Scalars['String'];
};


export type MutationCreateBillingDetailsArgs = {
  amount: Scalars['MoneyAmount'];
  currency: Currency;
  depositType?: InputMaybe<WalletDepositType>;
  redirectUrl?: InputMaybe<Scalars['String']>;
};


export type MutationCreateNeoCryptoGameTicketPaymentLinkArgs = {
  gameId: Scalars['ID'];
};


export type MutationCreateTelegramStarsInvoiceLinkArgs = {
  amount: Scalars['MoneyAmount'];
  currency: Currency;
};


export type MutationCreateTonkeeperGameTicketPaymentLinkArgs = {
  gameId: Scalars['ID'];
};


export type MutationHandleNeoCryptoPaymentArgs = {
  neoCryptoReqId: Scalars['String'];
  paymentId: Scalars['ID'];
};


export type MutationSendTelegramStarsInvoiceArgs = {
  amount: Scalars['MoneyAmount'];
  currency: Currency;
};


export type MutationSignInViaTonConnectArgs = {
  locale: Scalars['String'];
  tonProof: TonProofInput;
  walletName?: InputMaybe<Scalars['String']>;
};


export type MutationStakeTokensArgs = {
  stakeDetails: StakeDetailsInput;
};


export type MutationSubmitGameArgs = {
  prototypeGameId: Scalars['ID'];
  requestId: Scalars['String'];
};


export type MutationSubmitTeamArgs = {
  team: TeamInput;
};


export type MutationSwapTokensArgs = {
  fromTokenAmount: Scalars['MoneyAmount'];
  fromTokenCurrency: Currency;
  toTokenCurrency: Currency;
};


export type MutationWithdrawFromWalletArgs = {
  amount: Scalars['MoneyAmount'];
  currency: Currency;
  toWalletAddress: Scalars['String'];
};


export type MutationYieldGiftPackArgs = {
  gameId: Scalars['ID'];
};


export type MutationYieldGiftPackForLeaderboardChallengeArgs = {
  leaderboardChallengeId: Scalars['ID'];
};

export type NftCardGiveaway = Node & {
  __typename?: 'NftCardGiveaway';
  cardFilter: CardFilter;
  createdAt: Scalars['Time'];
  expiresAt?: Maybe<Scalars['Time']>;
  game?: Maybe<Game>;
  id: Scalars['ID'];
  isClaimed: Scalars['Boolean'];
  rewardedCard?: Maybe<Card>;
  userTeamId?: Maybe<Scalars['ID']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PaymentLink = {
  __typename?: 'PaymentLink';
  link: Scalars['String'];
  wallet: WalletProvider;
};

export type Player = Node & {
  __typename?: 'Player';
  avgScoreForLast15?: Maybe<Scalars['Int']>;
  cards: CardConnection;
  citizenship: Array<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  photo?: Maybe<Scalars['URL']>;
  scoreByMatch: Array<PlayerScore>;
  teamIds: Array<Scalars['ID']>;
  teams: TeamPlayerConnection;
  value?: Maybe<Money>;
};


export type PlayerCardsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type PlayerNameArgs = {
  locale?: InputMaybe<Locale>;
};


export type PlayerTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type PlayerEvent = Node & {
  __typename?: 'PlayerEvent';
  id: Scalars['ID'];
  matchEvent: MatchEvent;
  subtype?: Maybe<Scalars['String']>;
  teamPlayer: TeamPlayer;
  type: PlayerEventType;
};

export enum PlayerEventType {
  Assist = 'ASSIST',
  Finish = 'FINISH',
  Goal = 'GOAL',
  In = 'IN',
  MissedPenalty = 'MISSED_PENALTY',
  Out = 'OUT',
  OwnGoal = 'OWN_GOAL',
  PenaltyFoul = 'PENALTY_FOUL',
  PenaltyGoal = 'PENALTY_GOAL',
  RedCard = 'RED_CARD',
  SavedPenalty = 'SAVED_PENALTY',
  SecondYellowCard = 'SECOND_YELLOW_CARD',
  Start = 'START',
  YellowCard = 'YELLOW_CARD'
}

export enum PlayerPosition {
  Back = 'BACK',
  Forward = 'FORWARD',
  Goalkeeper = 'GOALKEEPER',
  Midfield = 'MIDFIELD',
  Reserve = 'RESERVE'
}

export type PlayerScore = {
  __typename?: 'PlayerScore';
  matchDay: Scalars['Time'];
  matchId: Scalars['ID'];
  points: Scalars['Int'];
};

export type PlayerStatValue = Node & {
  __typename?: 'PlayerStatValue';
  id: Scalars['ID'];
  match: Match;
  teamPlayer: TeamPlayer;
  type: PlayerStatValueType;
  value?: Maybe<Scalars['String']>;
};

export enum PlayerStatValueType {
  AccurateCrosses = 'ACCURATE_CROSSES',
  AccuratePasses = 'ACCURATE_PASSES',
  AccuratePassesPercentage = 'ACCURATE_PASSES_PERCENTAGE',
  AerialsWon = 'AERIALS_WON',
  Assists = 'ASSISTS',
  BigChancesCreated = 'BIG_CHANCES_CREATED',
  BigChancesMissed = 'BIG_CHANCES_MISSED',
  BlockedShots = 'BLOCKED_SHOTS',
  Captain = 'CAPTAIN',
  Clearances = 'CLEARANCES',
  ClearanceOffline = 'CLEARANCE_OFFLINE',
  Dispossessed = 'DISPOSSESSED',
  DribbledAttempts = 'DRIBBLED_ATTEMPTS',
  DribbledPast = 'DRIBBLED_PAST',
  DuelsLost = 'DUELS_LOST',
  DuelsWon = 'DUELS_WON',
  ErrorLeadToGoal = 'ERROR_LEAD_TO_GOAL',
  Fouls = 'FOULS',
  FoulsDrawn = 'FOULS_DRAWN',
  GoalkeeperGoalsConceded = 'GOALKEEPER_GOALS_CONCEDED',
  Goals = 'GOALS',
  GoalsConceded = 'GOALS_CONCEDED',
  HitWoodwork = 'HIT_WOODWORK',
  Interceptions = 'INTERCEPTIONS',
  KeyPasses = 'KEY_PASSES',
  LongBalls = 'LONG_BALLS',
  LongBallsWon = 'LONG_BALLS_WON',
  MinutesPlayed = 'MINUTES_PLAYED',
  MissedPasses = 'MISSED_PASSES',
  Offsides = 'OFFSIDES',
  OwnGoals = 'OWN_GOALS',
  Passes = 'PASSES',
  PenaltiesCommitted = 'PENALTIES_COMMITTED',
  PenaltiesMisses = 'PENALTIES_MISSES',
  PenaltiesSaved = 'PENALTIES_SAVED',
  PenaltiesScored = 'PENALTIES_SCORED',
  PenaltiesWon = 'PENALTIES_WON',
  Punches = 'PUNCHES',
  Rating = 'RATING',
  RedCards = 'RED_CARDS',
  Saves = 'SAVES',
  SavesInsideBox = 'SAVES_INSIDE_BOX',
  ShotsBlocked = 'SHOTS_BLOCKED',
  ShotsOffTarget = 'SHOTS_OFF_TARGET',
  ShotsOnTarget = 'SHOTS_ON_TARGET',
  ShotsTotal = 'SHOTS_TOTAL',
  SuccessfulDribbles = 'SUCCESSFUL_DRIBBLES',
  Tackles = 'TACKLES',
  ThroughBalls = 'THROUGH_BALLS',
  ThroughBallsWon = 'THROUGH_BALLS_WON',
  TotalCrosses = 'TOTAL_CROSSES',
  TotalDuels = 'TOTAL_DUELS',
  YellowCards = 'YELLOW_CARDS',
  YellowRedCards = 'YELLOW_RED_CARDS'
}

export type Query = {
  __typename?: 'Query';
  cardValues: CardValues;
  cards: CardConnection;
  currentLeaderboardChallenge?: Maybe<LeaderboardChallenge>;
  exchangeRates: Array<ExchangeRate>;
  gameValues: GameValues;
  games: GameConnection;
  leaderboardChallenges: LeaderboardChallengeConnection;
  lootBoxOffers: LootBoxOfferConnection;
  matches: MatchConnection;
  node?: Maybe<Node>;
  players: TeamPlayerConnection;
  scores: ScoreConnection;
  viewer: Viewer;
};


export type QueryCardValuesArgs = {
  citizenship?: InputMaybe<Array<Scalars['String']>>;
  excludedCards?: InputMaybe<Array<Scalars['ID']>>;
  games?: InputMaybe<Array<Scalars['ID']>>;
  myOwn?: InputMaybe<Scalars['Boolean']>;
  positions?: InputMaybe<Array<PlayerPosition>>;
  rarities?: InputMaybe<Array<Rarity>>;
  teams?: InputMaybe<Array<Scalars['ID']>>;
  tiers?: InputMaybe<Array<Scalars['Int']>>;
  tournaments?: InputMaybe<Array<Scalars['ID']>>;
  userTeams?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryCardsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  assistsAvg?: InputMaybe<IntRangeInput>;
  assistsLast5?: InputMaybe<IntRangeInput>;
  citizenship?: InputMaybe<Array<Scalars['String']>>;
  cleanSheetsAvg?: InputMaybe<IntRangeInput>;
  cleanSheetsLast5?: InputMaybe<IntRangeInput>;
  commonPackId?: InputMaybe<Scalars['ID']>;
  excludedCards?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  games?: InputMaybe<Array<Scalars['ID']>>;
  goalsAvg?: InputMaybe<IntRangeInput>;
  goalsLast5?: InputMaybe<IntRangeInput>;
  isAtAuction?: InputMaybe<Scalars['Boolean']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  isOnSale?: InputMaybe<Scalars['Boolean']>;
  minutesPlayedAvg?: InputMaybe<IntRangeInput>;
  minutesPlayedLast5?: InputMaybe<IntRangeInput>;
  myOwn?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<CardOrder>;
  positions?: InputMaybe<Array<PlayerPosition>>;
  price?: InputMaybe<MoneyRangeInput>;
  rarities?: InputMaybe<Array<Rarity>>;
  teams?: InputMaybe<Array<Scalars['ID']>>;
  tiers?: InputMaybe<Array<Scalars['Int']>>;
  tournaments?: InputMaybe<Array<Scalars['ID']>>;
  userTeams?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryGameValuesArgs = {
  exactTournaments?: InputMaybe<Array<Scalars['ID']>>;
  hasGiftToClaim?: InputMaybe<Scalars['Boolean']>;
  isCreatedByMe?: InputMaybe<Scalars['Boolean']>;
  isMainEvent?: InputMaybe<Scalars['Boolean']>;
  isNft?: InputMaybe<Scalars['Boolean']>;
  isOneMatchGame?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isPvP?: InputMaybe<Scalars['Boolean']>;
  isSharedByMe?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  lifecycle?: InputMaybe<Array<GameLifecycleStage>>;
  myOwn?: InputMaybe<Scalars['Boolean']>;
  ticketPrice?: InputMaybe<MoneyRangeInput>;
  tournaments?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  exactTournaments?: InputMaybe<Array<Scalars['ID']>>;
  features?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  hasGiftToClaim?: InputMaybe<Scalars['Boolean']>;
  isCreatedByMe?: InputMaybe<Scalars['Boolean']>;
  isMainEvent?: InputMaybe<Scalars['Boolean']>;
  isNft?: InputMaybe<Scalars['Boolean']>;
  isOneMatchGame?: InputMaybe<Scalars['Boolean']>;
  isPaid?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isPvP?: InputMaybe<Scalars['Boolean']>;
  isSharedByMe?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  lifecycle?: InputMaybe<Array<GameLifecycleStage>>;
  myOwn?: InputMaybe<Scalars['Boolean']>;
  ticketPrice?: InputMaybe<MoneyRangeInput>;
  tournaments?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryLeaderboardChallengesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  hasGiftToClaim?: InputMaybe<Scalars['Boolean']>;
};


export type QueryLootBoxOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type QueryMatchesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  players?: InputMaybe<Array<Scalars['ID']>>;
  teams?: InputMaybe<Array<Scalars['ID']>>;
  tournaments?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryPlayersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};


export type QueryScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  cards?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  games?: InputMaybe<Array<Scalars['ID']>>;
  teams?: InputMaybe<Array<Scalars['ID']>>;
};

export enum Rarity {
  Common = 'COMMON',
  Epic = 'EPIC',
  Legendary = 'LEGENDARY',
  Rare = 'RARE'
}

export type Score = Node & {
  __typename?: 'Score';
  card: Card;
  createdAt: Scalars['Time'];
  event: PlayerEvent;
  id: Scalars['ID'];
  points: Scalars['Int'];
  ruleId: Scalars['ID'];
  type: ScoreEventType;
};

export type ScoreConnection = {
  __typename?: 'ScoreConnection';
  edges: Array<ScoreEdge>;
  pageInfo: PageInfo;
};

export type ScoreEdge = {
  __typename?: 'ScoreEdge';
  cursor: Scalars['Cursor'];
  node: Score;
};

export enum ScoreEventType {
  Gain = 'GAIN',
  Loss = 'LOSS'
}

export type SeasonTicketReward = Node & {
  __typename?: 'SeasonTicketReward';
  createdAt: Scalars['Time'];
  expiresAt?: Maybe<Scalars['Time']>;
  game?: Maybe<Game>;
  gameFilter: GameFilter;
  id: Scalars['ID'];
  isClaimed: Scalars['Boolean'];
  userTeamId?: Maybe<Scalars['ID']>;
};

export type SeasonTicketSummary = {
  __typename?: 'SeasonTicketSummary';
  groupedByLabel: Array<SeasonTicketSummaryByLabel>;
  paidCommonGameTickets: Scalars['Int'];
  paidNftGameTickets: Scalars['Int'];
};

export type SeasonTicketSummaryByLabel = {
  __typename?: 'SeasonTicketSummaryByLabel';
  label: Scalars['String'];
  numberOfTickets: Scalars['Int'];
};

export type StakeDetails = {
  __typename?: 'StakeDetails';
  deposit: Money;
  duration: Scalars['Int'];
  valueClass: StakeValueClass;
};

export type StakeDetailsInput = {
  depositAmount: Scalars['MoneyAmount'];
  depositCurrency: Currency;
  duration: Scalars['Int'];
  valueClass: StakeValueClass;
};

export enum StakeValueClass {
  Bronze = 'BRONZE',
  Gold = 'GOLD',
  Platinum = 'PLATINUM',
  Silver = 'SILVER'
}

export type Team = Node & {
  __typename?: 'Team';
  id: Scalars['ID'];
  logo?: Maybe<Scalars['URL']>;
  name: Scalars['String'];
};


export type TeamNameArgs = {
  locale?: InputMaybe<Locale>;
};

export type TeamInput = {
  captainCardId?: InputMaybe<Scalars['ID']>;
  cardIds: Array<Scalars['ID']>;
  gameId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  requestId?: InputMaybe<Scalars['String']>;
};

export type TeamPlayer = Node & {
  __typename?: 'TeamPlayer';
  id: Scalars['ID'];
  number?: Maybe<Scalars['Int']>;
  player: Player;
  position: PlayerPosition;
  team: Team;
  tournament: Tournament;
};

export type TeamPlayerConnection = {
  __typename?: 'TeamPlayerConnection';
  edges: Array<TeamPlayerEdge>;
  pageInfo: PageInfo;
};

export type TeamPlayerEdge = {
  __typename?: 'TeamPlayerEdge';
  cursor: Scalars['Cursor'];
  node: TeamPlayer;
};

export type TonConnectDomainInput = {
  lengthBytes: Scalars['Int'];
  value: Scalars['String'];
};

export type TonProofInput = {
  address: Scalars['String'];
  network: Scalars['String'];
  proof: TonProofMessageInfoInput;
};

export type TonProofMessageInfoInput = {
  domain: TonConnectDomainInput;
  payload: Scalars['String'];
  signature: Scalars['String'];
  stateInit: Scalars['String'];
  timestamp: Scalars['Time'];
};

export type Tournament = Node & {
  __typename?: 'Tournament';
  id: Scalars['ID'];
  logo?: Maybe<Scalars['URL']>;
  name: Scalars['String'];
  season: Scalars['String'];
};


export type TournamentNameArgs = {
  locale?: InputMaybe<Locale>;
};

export type TournamentTeam = Node & {
  __typename?: 'TournamentTeam';
  id: Scalars['ID'];
  place?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
  team: Team;
  tournament: Tournament;
};

export type UserAccount = {
  __typename?: 'UserAccount';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UserTeam = Node & {
  __typename?: 'UserTeam';
  account: UserAccount;
  captain?: Maybe<Card>;
  cards: Array<Card>;
  game: Game;
  giftsLeft?: Maybe<Scalars['Int']>;
  groupIndex: Scalars['Int'];
  id: Scalars['ID'];
  leaderboardPoints?: Maybe<Scalars['Int']>;
  numberInGame?: Maybe<Scalars['Int']>;
  place: Scalars['Int'];
  registrationType: UserTeamRegistrationType;
  score: Scalars['Int'];
};

export type UserTeamConnection = {
  __typename?: 'UserTeamConnection';
  edges: Array<UserTeamEdge>;
  pageInfo: PageInfo;
};

export type UserTeamEdge = {
  __typename?: 'UserTeamEdge';
  cursor: Scalars['Cursor'];
  node: UserTeam;
};

export enum UserTeamRegistrationType {
  Free = 'FREE',
  Ticket = 'TICKET',
  Wallet = 'WALLET'
}

export type Viewer = {
  __typename?: 'Viewer';
  cards: CardConnection;
  features: Array<Scalars['ID']>;
  gameStats: ViewerGameStats;
  gameTickets: Array<Scalars['ID']>;
  gifts: GiftConnection;
  id: Scalars['ID'];
  inviterStats: InviterStats;
  name?: Maybe<Scalars['String']>;
  onboardingSteps: Array<Scalars['ID']>;
  registeredAt: Scalars['Time'];
  scores: ScoreConnection;
  startToken?: Maybe<Scalars['String']>;
  teams: UserTeamConnection;
  tonWallets: Array<Scalars['String']>;
  wallet: Wallet;
  webappFirstStartedAt?: Maybe<Scalars['Time']>;
};


export type ViewerCardsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  citizenship?: InputMaybe<Array<Scalars['String']>>;
  excludedCards?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  positions?: InputMaybe<Array<PlayerPosition>>;
  rarities?: InputMaybe<Array<Rarity>>;
  teams?: InputMaybe<Array<Scalars['ID']>>;
  tiers?: InputMaybe<Array<Scalars['Int']>>;
  tournaments?: InputMaybe<Array<Scalars['ID']>>;
};


export type ViewerGiftsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  games?: InputMaybe<Array<Scalars['ID']>>;
  isClaimed?: InputMaybe<Scalars['Boolean']>;
};


export type ViewerScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  cards?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  games?: InputMaybe<Array<Scalars['ID']>>;
  teams?: InputMaybe<Array<Scalars['ID']>>;
};


export type ViewerTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ViewerGameStats = {
  __typename?: 'ViewerGameStats';
  hasPlayedNftGames: Scalars['Boolean'];
  hasPlayedPaidGames: Scalars['Boolean'];
  numberOfGamesPlayed: Scalars['Int'];
};

export type Wallet = {
  __typename?: 'Wallet';
  availableCurrencies: Array<Currency>;
  balance: Money;
  defaultCurrency: Currency;
  seasonTicketSummary: SeasonTicketSummary;
  stakes: Array<StakeDetails>;
  tokens: Array<WalletToken>;
  transactions: WalletTransactionConnection;
};


export type WalletTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
};

export enum WalletDepositType {
  Purchase = 'PURCHASE',
  Transfer = 'TRANSFER'
}

export enum WalletProvider {
  Neocrypto = 'NEOCRYPTO',
  Stars = 'STARS',
  Tonkeeper = 'TONKEEPER',
  Tonspace = 'TONSPACE',
  Trust = 'TRUST',
  Wallet = 'WALLET'
}

export type WalletToken = {
  __typename?: 'WalletToken';
  balance: Money;
  isConvertibleTo: Array<Currency>;
};

export type WalletTransaction = Node & {
  __typename?: 'WalletTransaction';
  actualSum: Money;
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  game?: Maybe<Game>;
  id: Scalars['ID'];
  lootBoxOffer?: Maybe<LootBoxOffer>;
  opType: WalletTransactionOpType;
  status: WalletTransactionStatus;
  type: WalletTransactionType;
  walletSum: Money;
};

export type WalletTransactionConnection = {
  __typename?: 'WalletTransactionConnection';
  edges: Array<WalletTransactionEdge>;
  pageInfo: PageInfo;
};

export type WalletTransactionEdge = {
  __typename?: 'WalletTransactionEdge';
  cursor: Scalars['Cursor'];
  node: WalletTransaction;
};

export enum WalletTransactionOpType {
  GameTicket = 'GAME_TICKET',
  LootBox = 'LOOT_BOX',
  MoneyReward = 'MONEY_REWARD',
  SeasonTicket = 'SEASON_TICKET',
  TokenSwap = 'TOKEN_SWAP',
  Topup = 'TOPUP',
  Withdrawal = 'WITHDRAWAL'
}

export enum WalletTransactionStatus {
  Approved = 'APPROVED',
  ApproveRequired = 'APPROVE_REQUIRED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Started = 'STARTED'
}

export enum WalletTransactionType {
  Deposit = 'DEPOSIT',
  Withdrawal = 'WITHDRAWAL'
}
