import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Currency, GameLifecycleStage } from '@Global/apollo/types';
import { EGameLabel, ENftLeague } from '@Global/consts';

export type TGamesFilterInitialState = {
  isSingleMatch: boolean | null;
  labels: (EGameLabel | ENftLeague)[] | null;
  price: number | null;
  currency: Currency | null;
  lifecycle?: GameLifecycleStage[] | null;
  isNft?: boolean | null;
  isPvp?: boolean | null;
  isMAIN?: boolean;
};

export type TGamesFilterSliceProto = {
  initialState: TGamesFilterInitialState;
  reducers: SliceCaseReducers<TGamesFilterInitialState>;
};

export type TPriceOption = {
  amount: number;
  currency: Currency;
};

export const gamesFilterSliceProto: TGamesFilterSliceProto = {
  initialState: {
    isSingleMatch: null,
    labels: null,
    price: null,
    currency: null,
    lifecycle: [GameLifecycleStage.Ongoing, GameLifecycleStage.Upcoming, GameLifecycleStage.Finished],
    isNft: null,
    isPvp: null,
    isMAIN: true,
  },
  reducers: {
    setIsSingleMatch: (state, action: PayloadAction<boolean | null>) => {
      state.isSingleMatch = action.payload;
    },
    setLabels: (state, action: PayloadAction<EGameLabel[] | null>) => {
      state.labels = action.payload;
    },
    setIsNft: (state, action: PayloadAction<boolean | null>) => {
      state.isNft = action.payload;
    },
    setPrice: (state, action: PayloadAction<TPriceOption | null>) => {
      state.price = action.payload?.amount ?? null;
      state.currency = action.payload?.currency || null;
    },
    setLifecycle: (state, action: PayloadAction<GameLifecycleStage[] | null>) => {
      state.lifecycle = action.payload;
    },
    setIsPvp: (state, action: PayloadAction<boolean>) => {
      state.isPvp = action.payload;
    },
    setIsMAIN: (state, action: PayloadAction<boolean>) => {
      state.isMAIN = action.payload;
    },
    reset: (state) => {
      state.isSingleMatch = null;
      state.labels = null;
      state.isNft = null;
      state.price = null;
      state.lifecycle = [GameLifecycleStage.Ongoing, GameLifecycleStage.Upcoming, GameLifecycleStage.Finished];
      state.isPvp = null;
      state.isMAIN = true;
    },
  },
};
