import { getTelegramUserInfo } from '@Utils/telegram';

export function getUserLanguage() {
  const tgUser = getTelegramUserInfo();

  if (tgUser && tgUser.language_code) {
    return tgUser.language_code;
  }

  return (navigator.language || 'en-GB').toLowerCase();
}

export function getUser2LetterLanguageCode() {
  const code = getUserLanguage().split('-')[0] || 'en';

  if (['en', 'pt', 'ru', 'it', 'es'].includes(code)) {
    return code;
  }

  return 'en';
}
