import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Card, PlayerPosition } from '@Global/apollo/types';

const RequiredPlayerPositions = [
  PlayerPosition.Goalkeeper,
  PlayerPosition.Forward,
  PlayerPosition.Midfield,
  PlayerPosition.Back,
];

declare global {
  type TTeam = {
    id?: string;
    gameId?: string;
    [PlayerPosition.Goalkeeper]?: Card | null;
    [PlayerPosition.Forward]?: Card | null;
    [PlayerPosition.Midfield]?: Card | null;
    [PlayerPosition.Back]?: Card | null;
    [PlayerPosition.Reserve]?: Card | null;
    captain?: Card | null;
  };
}

type TTeamContext = {
  initialTeam: TTeam | null;
  team: TTeam;
  getCachedNewTeam: (gameId: string) => TTeam | null;
  updateTeam: (updatedPositions: Partial<TTeam>, isToUpdateInitialTeam?: boolean) => void;
  resetTeam: () => void;
  captain?: Card | null;
  setCaptain: (newCaptain: TTeam['captain']) => void;
  captainChanged: boolean;
  setCaptainChanged: (x: boolean) => void;
};

const initialState: TTeamContext = {
  initialTeam: null,
  team: {},
  getCachedNewTeam: () => null,
  updateTeam: () => {},
  resetTeam: () => {},
  setCaptain: (x) => {},
  captainChanged: false,
  setCaptainChanged: () => {},
};

type TTeamContextProviderOwnProps = {
  setUserFilter: (x: any) => void;
} & PropsWithChildren;

export const TeamContext = React.createContext(initialState);

export const TeamContextProvider: React.FC<TTeamContextProviderOwnProps> = ({ children, setUserFilter }) => {
  const [initialTeam, setInitialTeam] = useState<TTeam | null>(null);
  const [captainChanged, setCaptainChanged] = useState(false);
  const [team, setTeam] = useState<TTeam>({
    [PlayerPosition.Goalkeeper]: null,
    [PlayerPosition.Forward]: null,
    [PlayerPosition.Midfield]: null,
    [PlayerPosition.Back]: null,
    [PlayerPosition.Reserve]: null,
  });

  // const [captain, setCaptain] = useState<TTeam['captain']>();

  const setCaptainAndMarkChanged = (newCaptain: Card | undefined | null) => {
    if (!newCaptain) {
      return;
    }

    setTeam((prev) => {
      if (prev.captain) {
        setCaptainChanged(true);
      }

      const newTeam = { ...prev, captain: newCaptain };

      if (!prev.id) {
        localStorage.setItem(`team:1:${newTeam.gameId}`, JSON.stringify(newTeam));
      }

      return newTeam;
    });
  };

  const onUpdateTeam = useCallback(
    (updatedPositions: Partial<TTeam>, isToUpdateInitialTeam?: boolean) => {
      const updatedPositionsKeys = Object.keys(updatedPositions).filter((key) =>
        RequiredPlayerPositions.includes(key as PlayerPosition),
      );
      setTeam((prev) => {
        if (updatedPositions.id === 'new') {
          updatedPositions.id = undefined;
        }

        let captain = updatedPositions.captain || prev.captain;
        if (updatedPositionsKeys.length >= 1 && prev.captain !== null && updatedPositions.captain === undefined) {
          if (captain) {
            if (team[updatedPositionsKeys[0] as PlayerPosition]?.id === captain?.id) {
              captain = updatedPositions[updatedPositionsKeys[0] as PlayerPosition];
            }
          } else if (updatedPositionsKeys[0] !== PlayerPosition.Reserve) {
            captain = updatedPositions[updatedPositionsKeys[0] as PlayerPosition];
          }
        }

        const newTeam = { ...prev, ...updatedPositions, captain };

        if (!newTeam.id) {
          localStorage.setItem(`team:1:${newTeam.gameId}`, JSON.stringify(newTeam));
        } else {
          localStorage.removeItem(`team:1:${newTeam.gameId}`);
        }

        return newTeam;
      });
      setInitialTeam((prev) => (!prev || isToUpdateInitialTeam ? { ...prev, ...updatedPositions } : prev));
    },
    [team],
  );

  const getCachedNewTeam = useCallback((gameId: string) => {
    const cachedTeam = localStorage.getItem(`team:1:${gameId}`);
    if (cachedTeam) {
      return JSON.parse(cachedTeam) as TTeam;
    }

    return null;
  }, []);

  const onResetTeam = useCallback(() => {
    setTeam({});
    setInitialTeam(null);
  }, []);

  return (
    <TeamContext.Provider
      value={{
        initialTeam,
        team,
        getCachedNewTeam: getCachedNewTeam,
        updateTeam: onUpdateTeam,
        resetTeam: onResetTeam,
        captain: team.captain,
        setCaptain: setCaptainAndMarkChanged,
        setCaptainChanged,
        captainChanged,
      }}
    >
      {' '}
      {children}{' '}
    </TeamContext.Provider>
  );
};
