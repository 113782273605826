import { mapByDay } from './mapByDay';

export const addHeadingZeros = (target: number, finalLen: number): string => {
  const stringTagret = target.toString();

  if (finalLen <= stringTagret.length) {
    return stringTagret;
  }

  return '0'.repeat(finalLen - stringTagret.length) + target;
};

export { mapByDay };
