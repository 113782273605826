import { useContext, useEffect } from 'react';
import { useGetAppViewerQuery } from './gql/getAppViewer.generated';
import { ViewerContext } from '@Global/store';

/** хук запроса возвращающий стартовые флаги  */
export const useAppViewer = () => {
  const viewerCtx = useContext(ViewerContext);
  const { loading, data, refetch } = useGetAppViewerQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading && data) {
      viewerCtx.updateViewer(data.viewer, data.viewer.id);
    }
  }, [loading, data]);

  return {
    loading,
    data,
    refetch,
  };
};
