import { initReactI18next } from 'react-i18next';
import { getUser2LetterLanguageCode } from '@Utils/getUserLanguage';
import i18n from 'i18next';
import ENTranslations from './locales/en/translations';
import ESTranslations from './locales/es/translations';
import ITTranslation from './locales/it/translations';
import PTTranslation from './locales/pt/translations';
import RUTranslations from './locales/ru/translations';

const initI18n = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: getUser2LetterLanguageCode(),
    resources: {
      en: {
        translations: ENTranslations,
      },
      es: {
        translations: ESTranslations,
      },
      ru: {
        translations: RUTranslations,
      },
      pt: {
        translations: PTTranslation,
      },
      it: {
        translations: ITTranslation,
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });

  i18n.languages = ['en', 'es', 'ru', 'pt', 'it'];
};

export default initI18n;
