/** функция слияния рутов */
function pathJoin(...parts) {
  const separator = '/';
  const replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

/** получение параметров точки доступа сервера */
export function getUriSource() {
  if (process.env.NODE_ENV !== 'development') return pathJoin(process.env.PUBLIC_URL, '/graphql');

  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return process.env.REACT_APP_URI;
    case 'qa':
      return process.env.REACT_APP_QA_URI;
    case 'prod':
      return process.env.REACT_APP_PROD_URI;
    default:
      return 'https://app.fan-ton.com/graphql';
  }
}
