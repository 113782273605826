import React from 'react';

// TODO: нужна доработка фолбека с нормальным лоадером
/** компонент замены блока рендера на плейсхолдер во время загрузки */
export const Suspense = ({ className = '', children, loading, fallback }) => {
  if (loading) {
    return <div className={className}>{fallback()}</div>;
  }

  return className ? <div className={className}>{children}</div> : <>{children}</>;
};
