import { GameLifecycleStage } from '@Global/apollo/types';
import { EGameLabel } from '@Global/consts';

export const NFT_PRO_REQUIREMENTS_SYSTEM_ID = 'vpGYBSDHtBNd26Bxhvr8qPCU';
export const NFT_AMATEUR_REQUIREMENTS_SYSTEM_ID = 'vpER5tTQH-ZSiJtmJJ3Lv2FE';
export const NFT_LEGENDARY_REQUIREMENTS_SYSTEM_ID = 'vpGlMEWq3T5dB5ODP4pUB3yF';
export const NFT_BEGINNER_REQUIREMENTS_SYSTEM_ID = 'vpFw5X851iZTgpz55k4bdrBF';

export const SEASON_TICKET_FEATURE_ID = 'season_ticket';

export const upcomingLifecycle = [GameLifecycleStage.Upcoming];

export enum EGameTasksCheckpoint {
  GAME_ENTER = 'game_enter',
  BEFORE_TEAM_SUBMISSION = 'before_team_submission',
}

export const getTournamentLabelById = (id: string): EGameLabel | null => {
  switch (id) {
    case 'itENCvhIIfBUwL7g6Cmxp5MT':
      return EGameLabel.SERIE_A;
    case 'itEnBG-Y3xNRu5C3SEauiiZj':
      return EGameLabel.LIGA1;
    case 'itFmFrePKMhc3ZseJTeZ2FXG':
      return EGameLabel.EURO2024;
    case 'itFm5c1AUGNcjLP_6y45dSpR':
      return EGameLabel.EUROPA_LEAGUE;
    case 'itFzl7eLf31eU5QbPjQBTMBh':
      return EGameLabel.CL;
    case 'itF5IMz_DW9SmJusOftX7N8m':
      return EGameLabel.EPL;
    case 'itGdUw_b6VxV6qkp-Xws2F4q':
      return EGameLabel.BUNDESLIGA;
    case 'itGoBKpvTuFdooYZHvfHVsfC':
      return EGameLabel.WC2022;
    case 'itG52Yc_SMFQa5lrqWSexg2r':
      return EGameLabel.BRA1;
    case 'itHijolQGgpVmoa_yc0rksLk':
      return EGameLabel.BRA1;
    case 'itHi6mCDGTVRW7tRiVrwvTDf':
      return EGameLabel.EUROPA_LEAGUE;
    case 'itHuEXML65NQLbovaP8IIWhR':
      return EGameLabel.LALIGA;
    case 'itEGLN8mNV5Z9r4BNfF5BC2q':
      return EGameLabel.MLS;
  }

  return null;
};
