/**
 * @param {Object} game - Объект с информацией о игре из БД
 * @returns {Object} - Объект с параметрами (название чемпионата, тура, платный/бесплатный) для отправки в Amplitude
 */
import { Game, Viewer } from '@Global/apollo/types';
import { EGameLabel, tournamentsLabels } from '@Global/consts';
import { getUserGameStatus, userIsParticipant } from '@Global/utils/games/getUserGameStatus';

export const getCommonGameParams = (game: Game, viewer: Viewer) => {
  if (!game || !viewer) {
    return {};
  }

  const status = getUserGameStatus(game, viewer);

  const isParticipant = userIsParticipant(game);

  const countryCode = game.labels.find((label) => tournamentsLabels.includes(label as EGameLabel));

  return {
    game_id: game.id,
    game_name: game.name,
    is_paid: game.isPaid,
    is_completed: game.isCompleted,
    is_nft: Boolean(game.isNft),
    is_part: isParticipant,
    championship: countryCode,
    status,
  };
};
