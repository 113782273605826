import { track } from '@amplitude/analytics-browser';
import { getPlatform } from '@Utils/platforms';

// Общие для всех событий поля
const commonProperties = {
  platform: getPlatform(),
};

export const trackEvent = (eventName: string, eventProperties = {}) => {
  track(eventName, {
    ...commonProperties,
    ...eventProperties,
    ...{
      time: new Date().toUTCString(),
    },
  });
};
