export const setupTelegramWindowObject = () => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  const defaultTelegramObject = {
    initDataUnsafe: {
      user: {
        id: 425974638,
        is_bot: false,
        first_name: 'Ivan',
        last_name: undefined,
        username: 'ivan_the_best',
        language_code: 'en',
        is_premium: undefined,
        photo_url: undefined,
      },
    },
    initData:
      'query_id=AAFu22MZAAAAAG7bYxmBpemy&user=%7B%22id%22%3A425974638%2C%22first_name%22%3A%22Timofey%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22qootee%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1712221494&hash=6258e6e5640a7802328c5cc16c702a527cec4d3806575ec3da6c20e9c3b76b89',
    openTelegramLink: (link) => console.log('openTelegramLink', link),
    enableClosingConfirmation: () => console.log('enableClosingConfirmation called'),
    expand: () => {},
    openInvoice: (url, callback) => {
      console.log('openInvoice', url);
      if (callback) {
        callback();
      }
    },
    showAlert: (msg) => {
      console.log('showAlert', msg);
    },
  };

  try {
    const envTelegramWebAppObjectStr = process.env.REACT_APP_WINDOW_TELEGRAM_WEBAPP_OBJECT_STR;

    const envTelegramWebAppObject = JSON.parse(envTelegramWebAppObjectStr);

    window.Telegram.WebApp = envTelegramWebAppObject;
  } catch (e) {
    window.Telegram.WebApp = defaultTelegramObject;
  }
};
