import { Rarity as ApolloRarity, Currency, PlayerPosition } from '@Apollo/types';
import { EGameLabel } from './consts';

/** позиции */
export const Positions = {
  [PlayerPosition.Goalkeeper]: 'common.positions.goalkeeper',
  [PlayerPosition.Forward]: 'common.positions.forward',
  [PlayerPosition.Midfield]: 'common.positions.midfielder',
  [PlayerPosition.Back]: 'common.positions.defender',
};

/** рарность */
export const Rarity = {
  [ApolloRarity.Common]: 'Common',
  [ApolloRarity.Rare]: 'Rare',
  [ApolloRarity.Epic]: 'Epic',
  [ApolloRarity.Legendary]: 'Legendary',
};

/** дополнительные флаги стран */
export const EXTRA_COUNTRIES = [EGameLabel.EPL, 'wls', 'sct', 'nir'];

export const TournamentsByCountryCode = {
  eng: ['itFBZO09uNlcmoZtJdoI6w-0'],
  it: ['itHbC9Sdxq1eGLhyAL8sLgTV'],
  es: ['itF4_mK96aRVJqVgroP4j7n4'],
  fr: ['itGkNCcixUda0IWWgQS3gMX4'],
  de: ['itFAI8topTRfdYhkAtDtKzl3'],
  eu: ['itFWdn9k-R5UnJJlLoFvgVrX'],
  br: ['itHb_YyZONBepJ_Ow-ksOLpA'],
  wc: ['itFd1pQMUfldU4TgKw8UHyfK'],
  emq: ['itEBhv_z1YV46JZ-oJfqmJJL'],
  top5: [
    'itFBZO09uNlcmoZtJdoI6w-0',
    'itHbC9Sdxq1eGLhyAL8sLgTV',
    'itF4_mK96aRVJqVgroP4j7n4',
    'itGkNCcixUda0IWWgQS3gMX4',
    'itFAI8topTRfdYhkAtDtKzl3',
  ],
};

export const CountryCodeByTournament = {
  'itFBZO09uNlcmoZtJdoI6w-0': 'eng',
  itHbC9Sdxq1eGLhyAL8sLgTV: 'it',
  itF4_mK96aRVJqVgroP4j7n4: 'es',
  itGkNCcixUda0IWWgQS3gMX4: 'fr',
  itFAI8topTRfdYhkAtDtKzl3: 'de',
  'itFWdn9k-R5UnJJlLoFvgVrX': 'eu',
  'itHb_YyZONBepJ_Ow-ksOLpA': 'br',
  itFd1pQMUfldU4TgKw8UHyfK: 'wc',
  'itEBhv_z1YV46JZ-oJfqmJJL': 'emq',
};

export const getCountryCodeByTournament = (tournaments) =>
  tournaments.length === 1 ? CountryCodeByTournament[tournaments[0]] : 'top5';

export const GuestAccountID = 'MYEk3x34-3hVZoylmfNga1py';

export const JwtTokenName = process.env.REACT_APP_JWT_TOKEN_NAME || 'token';

export const IS_QA = process.env.REACT_APP_ENVIRONMENT === 'qa' || window.location.pathname.startsWith('/qa/');

export const MAX_TON_AMOUNT = 100000000;

// Control payment's priority flows
export const IS_ONLY_TELEGRAM_STARS_FLOW = false;
export const IS_UNITED_DEPOSIT_PAGE_FLOW = true;
