import { createSlice } from '@reduxjs/toolkit';
import { upcomingLifecycle } from '@Global/utils/games/consts';
import { gamesFilterSliceProto, TGamesFilterInitialState } from './consts';

export const homeScreenAllGamesFilters = createSlice({
  name: 'homeScreenAllGamesFilters',
  ...{
    ...gamesFilterSliceProto,
    initialState: {
      ...gamesFilterSliceProto.initialState,
      lifecycle: upcomingLifecycle,
    } as TGamesFilterInitialState,
  },
});

export const homeScreenAllGamesFiltersReducer = homeScreenAllGamesFilters.reducer;
