import { useGetExchangeRatesLazyQuery } from './gql/getExchangeRates.generated';

export const useExchangeRates = () => {
  const [fetchExchangeRates, { loading }] = useGetExchangeRatesLazyQuery({
    fetchPolicy: 'network-only',
  });

  return {
    fetchExchangeRates,
    loading,
  };
};
