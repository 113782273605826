import { useLayoutEffect } from 'react';
import { parseStartParamDataFromQuery } from '@Global/utils/tgWebAppStartParam/parseStartParamDataFromQuery';

export const useParseStartParamDataEffect = () => {
  useLayoutEffect(() => {
    const parsedData = parseStartParamDataFromQuery(new URLSearchParams(window.location.search));
    if (parsedData.startPath) {
      window.history.replaceState({}, '', parsedData.startPath);
    }
  }, []);
};
