const startParamQueryName = 'tgWebAppStartParam';

export type TStartParamData = {
  startPath?: string;
};

export const parseStartParamDataFromQuery = (urlParams: URLSearchParams): TStartParamData => {
  const startParamEncoded = urlParams.get(startParamQueryName);

  let startParam: TStartParamData | undefined;

  if (startParamEncoded) {
    try {
      const startParamDecoded = atob(startParamEncoded);
      startParam = JSON.parse(startParamDecoded);
    } catch (e) {
      //console.error('parseStartParamFromQuery error', e);
    }
  }

  return startParam || {};
};
