export const AMPLITUDE_EVENTS = {
  CARDSSCR_BTN_BACK: 'CARDSSCR_BTN_BACK',
  FIRSTSCR_APP_STARTED: 'FIRSTSCR_APP_STARTED',
  MAINSCR_BTN_SELECT_TOURNAMENT: 'MAINSCR_BTN_SELECT_TOURNAMENT',
  MAINSCR_NFTCARDS_SEE_ALL: 'MAINSCR_NFTCARDS_SEE_ALL',
  MAINSCR_TABS_SUPPORT_CLICK: 'MAINSCR_TABS_SUPPORT_CLICK',
  MAINSCR_TABS_PRIZES_CLICK: 'MAINSCR_TABS_PRIZES_CLICK',
  MAINSCR_TABS_BALANCE_CLICK: 'MAINSCR_TABS_BALANCE_CLICK',
  MAINSCR_TOURNAMENTS_SEE_ALL: 'MAINSCR_TOURNAMENTS_SEE_ALL',
  MAINSCR_MY_TOURNAMENTS_SEE_ALL: 'MAINSCR_MY_TOURNAMENTS_SEE_ALL',
  MY_TOURNAMENTS_BTN_BACK: 'MY_TOURNAMENTS_BTN_BACK',
  MY_TOURNAMENTS_START: 'MY_TOURNAMENTS_START',
  MAINSCR_PRIZES_SEE_ALL: 'MAINSCR_PRIZES_SEE_ALL',
  MAINSCR_PRIZES_PAID: 'MAINSCR_PRIZES_PAID',
  MAINSCR_PRIZES_FREE: 'MAINSCR_PRIZES_FREE',
  MAINSCR_BTN_PAIDCOMMON: 'MAINSCR_BTN_PAIDCOMMON',
  MAINSCR_BTN_PAIDNFT: 'MAINSCR_BTN_PAIDNFT',
  MAINSCR_BTN_FREECOMMON: 'MAINSCR_BTN_FREECOMMON',
  MAINSCR_BTN_FREENFT: 'MAINSCR_BTN_FREENFT',
  MAINSCR_NOTIFICATION_TAP: 'MAINSCR_NOTIFICATION_TAP',
  MAINSCR_SETTINGS_TAP: 'MAINSCR_SETTINGS_TAP',
  TEAMSCR_SCREEN_PRIZES_STARTED: 'TEAMSCR_SCREEN_PRIZES_STARTED',
  TEAMSCR_WALLET_OPENED: 'TEAMSCR_WALLET_OPENED',
  TEAMSCR_SCREEN_RESULTS_STARTED: 'TEAMSCR_SCREEN_RESULTS_STARTED',
  MAINSCR_TOURNAMENT_CHOOSE: 'MAINSCR_TOURNAMENT_CHOOSE',
  MAINSCR_MY_TOURNAMENT_CHOOSE: 'MAINSCR_MY_TOURNAMENT_CHOOSE',
  MY_TOURNAMENTSSCR_TOURNAMENT_CHOOSE: 'MY_TOURNAMENTSSCR_TOURNAMENT_CHOOSE',
  CHAMPIONSHIPSCR_TAB_COMMON: 'CHAMPIONSHIPSCR_TAB_COMMON',
  CHAMPIONSHIPSCR_TAB_NFT: 'CHAMPIONSHIPSCR_TAB_NFT',
  TOURNAMENTSSCR_CHAMPIONSHIP_CHOOSE: 'TOURNAMENTSSCR_CHAMPIONSHIP_CHOOSE',
  TOURNAMENTSSCR_BTN_BACK: 'TOURNAMENTSSCR_BTN_BACK',
  CHAMPIONSHIPSCR_TOURNAMENT_CHOOSE: 'CHAMPIONSHIPSCR_TOURNAMENT_CHOOSE',
  CHAMPIONSHIPSCR_TAB_CURRENT: 'CHAMPIONSHIPSCR_TAB_CURRENT',
  CHAMPIONSHIPSCR_TAB_PAST: 'CHAMPIONSHIPSCR_TAB_PAST',
  CHAMPIONSHIPSCR_BTN_BACK: 'CHAMPIONSHIPSCR_BTN_BACK',
  CHAMPIONSHIPSCR_BTN_CHOOSE_ANOTHER: 'CHAMPIONSHIPSCR_BTN_CHOOSE_ANOTHER',
  CHAMPIONSHIPSCR_START: 'CHAMPIONSHIPSCR_START',
  TEAMSCR_PLAYER_CHOOSE: 'TEAMSCR_PLAYER_CHOOSE',
  TEAMSCR_BTN_REGISTER: 'TEAMSCR_BTN_REGISTER',
  TEAMSCR_BTN_PAY: 'TEAMSCR_BTN_PAY',
  TEAMSCR_BTN_PAYCARD: 'TEAMSCR_BTN_PAYCARD',
  TOURNAMENT_WAS_PAID: 'TOURNAMENT_WAS_PAID',
  TEAMSCR_SCREEN_TEAM_STARTED: 'TEAMSCR_SCREEN_TEAM_STARTED',
  TEAMSCR_TAB_TEAM: 'TEAMSCR_TAB_TEAM',
  TEAMSCR_TAB_RESULTS: 'TEAMSCR_TAB_RESULTS',
  TEAMSCR_TAB_PRIZES: 'TEAMSCR_TAB_PRIZES',
  PRIZE_BTN_GETPRIZE: 'PRIZE_BTN_GETPRIZE',
  PRIZE_BTN_GET_TOP_PRIZE: 'PRIZE_BTN_GET_TOP_PRIZE',
  PRIZE_BTN_GET_СPACK: 'PRIZE_BTN_GET_СPACK',
  PRIZE_BTN_PLAYAGAIN: 'PRIZE_BTN_PLAYAGAIN',
  PACK_BTN_SELECT: 'PACK_BTN_SELECT',
  PACK_BTN_PLAY: 'PACK_BTN_PLAY',
  SELECTPLAYERSCR_CARD_CHOOSE: 'SELECTPLAYERSCR_CARD_CHOOSE',
  SELECTPLAYERSCR_BTN_FILTER: 'SELECTPLAYERSCR_BTN_FILTER',
  SELECTPLAYERSCR_TAB_COMMON: 'SELECTPLAYERSCR_TAB_COMMON/NFT',
  SELECTPLAYERSCR_TAB_NFT: 'SELECTPLAYERSCR_TAB_NFT',
  SELECTPLAYERSCR_FIELD_SEARCH: 'SELECTPLAYERSCR_FIELD_SEARCH',
  SELECTPLAYERSCR_BTN_BUY: 'SELECTPLAYERSCR_BTN_BUY',
  SELECTPLAYERSCR_BTN_BACK: 'SELECTPLAYERSCR_BTN_BACK',
  NOTIFICATION_BTN_COLLECT: 'NOTIFICATION_BTN_COLLECT',
  NOTIFICATION_BTN_GET_PRIZE: 'NOTIFICATION_BTN_GET_PRIZE',
  NOTIFICATION_BTN_BACK: 'NOTIFICATION_BTN_BACK',
  TOURNAMENTSSCR_BTN_FREE: 'TOURNAMENTSSCR_BTN_FREE',
  TOURNAMENTSSCR_BTN_PAID: 'TOURNAMENTSSCR_BTN_PAID',
  TOURNAMENTSSCR_START: 'TOURNAMENTSSCR_START',
  TEAMSCR_BTN_BACK: 'TEAMSCR_BTN_BACK',
  TEAMSCR_BTN_INFO: 'TEAMSCR_BTN_INFO',
  RESULTSCR_TEAM_DETAILS: 'RESULTSCR_TEAM_DETAILS',
  FILTERSCR_FILTER_CLUB: 'FILTERSCR_FILTER_CLUB',
  FILTERSCR_FILTER_COUNTRY: 'FILTERSCR_FILTER_COUNTRY',
  FILTERSCR_FILTER_CHAMP: 'FILTERSCR_FILTER_CHAMP',
  FILTERSCR_BTN_APPLY: 'FILTERSCR_BTN_APPLY',
  FILTERSCR_BTN_RESET: 'FILTERSCR_BTN_RESET',
  FILTERSCR_BTN_BACK: 'FILTERSCR_BTN_BACK',
  FILTERSCR_FILTER_TIER: 'FILTERSCR_FILTER_TIER',
  FILTERSCR_FILTER_POSITION: 'FILTERSCR_FILTER_POSITION',
  PRIZESSCR_STARTED: 'PRIZESSCR_STARTED',
  PRIZESSCR_BTN_COMMON: 'PRIZESSCR_BTN_COMMON',
  PRIZESSCR_BTN_PREMIUM: 'PRIZESSCR_BTN_PREMIUM',
  PRIZESSCR_BTN_NFT: 'PRIZESSCR_BTN_NFT',
  PRIZESSCR_BTN_BACK: 'PRIZESSCR_BTN_BACK',
  PRIZESSCR_BTN_PLAY_PREMIUM_VISIBLE: 'PRIZESSCR_BTN_PLAY_PREMIUM_VISIBLE',
  PRIZESSCR_BTN_PLAY_PREMIUM_CLICKED: 'PRIZESSCR_BTN_PLAY_PREMIUM_CLICKED',
  SUPPORTSCR_STARTED: 'SUPPORTSCR_STARTED',
  SUPPORTSCR_BTN_CONTACTSUPPORT: 'SUPPORTSCR_BTN_CONTACTSUPPORT',
  WALLETSCR_SCREEN_STARTED: 'WALLETSCR_SCREEN_STARTED',
  WALLETSCR_BTN_BACK: 'WALLETSCR_BTN_BACK',
  ENTERAMOUNT_BTN_BACK: 'ENTERAMOUNT_BTN_BACK',
  SELECTWALLET_BTN_BACK: 'SELECTWALLET_BTN_BACK',
  SENDMONEY_PAGE_BTN_BACK: 'SENDMONEY_PAGE_BTN_BACK',
  SWAPMONEY_PAGE_BTN_BACK: 'SWAPMONEY_PAGE_BTN_BACK',
  SELECTWITHDRAWALWALLET_PAGE_BTN_BACK: 'SELECTWITHDRAWALWALLET_PAGE_BTN_BACK',
  PREMIUM_ONBOARDING_SHOWN: 'PREMIUM_ONBOARDING_SHOWN',
  PREMIUM_ONBOARDING_BUTTON_MORE_INFO_CLICK: 'PREMIUM_ONBOARDING_BUTTONS_MORE_INFO_CLICK',
  PREMIUM_ONBOARDING_BUTTON_NO_CLICK: 'PREMIUM_ONBOARDING_BUTTON_NO_CLICK',
  PREMIUM_ONBOARDING_BUTTON_PLAY_PREMIUM_CLICK: 'PREMIUM_ONBOARDING_BUTTON_PLAY_PREMIUM_CLICK',
  MAINSCR_BTN_INVITE_FRIENDS_CLICK: 'MAINSCR_BTN_INVITE_FRIENDS_CLICK',
  REFERRAL_PROGRAM_SCREEN_BACK_BTN_CLICK: 'REFERRAL_PROGRAM_SCREEN_BACK_BTN_CLICK',
  WALLETSCR_BTN_SEND: 'WALLETSCR_BTN_SEND',
  WALLETSCR_BTN_RECEIVE: 'WALLETSCR_BTN_RECEIVE',
  WALLETSCR_BTN_SWAP: 'WALLETSCR_BTN_SWAP',
  WALLETSCR_BTN_STAKE: 'WALLETSCR_BTN_STAKE',
  SELECTDEPOSITMETHODSCR_BTN_BACK: 'SELECTDEPOMETHOD_BTN_BACK',
  SELECTDEPOSITMETHODSCR_METHOD_CHOOSE: 'SELECTDEPOSITMETHOD_METHOD_CHOOSE',
  ENTERAMOUNTSCR_BTN_CONTINUE: 'ENTERAMOUNTSCR_BTN_CONTINUE',
  SELECTWALLETSCR_WALLET_CHOOSE: 'SELECTWALLETSCR_WALLET_CHOOSE',
  OTHERWALLETSCR_BTN_RETURN_TO_BALANCE: 'OTHERWALLETSCR_BTN_RETURN_TO_BALANCE',
  BALANCE_REPENISHMENT_TONSCR_BTN_BACK: 'BALANCE_REPENISHMENT_TONSCR_BTN_BACK',
  BALANCE_REPENISHMENT_TONSCR_WALLET_CHOOSE: 'BALANCE_REPENISHMENT_TONSCR_WALLET_CHOOSE',
  BALANCE_REPENISHMENT_TONSCR_BTN_DEPOSIT: 'BALANCE_REPENISHMENT_TONSCR_BTN_DEPOSIT',
  BALANCE_REPENISHMENT_TONSCR_BTN_PAY_OTHER_CRYPTO: 'BALANCE_REPENISHMENT_TONSCR_BTN_PAY_OTHER_CRYPTO',
  BALANCE_REPENISHMENT_TONSCR_STARTED: 'BALANCE_REPENISHMENT_TONSCR_STARTED',
  TONSTARTER_BANNER_CLICK: 'TONSTARTER_BANNER_CLICK',
  GRAND_BANNER_CLICK: 'GRAND_BANNER_CLICK',
  CARDSSCR_TAB_MARKET: 'CARDSSCR_TAB_MARKET',
  CARDSSCR_TAB_MYNFT: 'CARDSSCR_TAB_MYNFT',
  CARDSSCR_TAB_LOOTBOX: 'CARDSSCR_TAB_LOOTBOX',
  FILTERSCR_FILTER_ADVANCED: 'FILTERSCR_FILTER_ADVANCED',
  ADVANCED_FILTERS_RESET: 'ADVANCED_FILTERS_RESET',
  ADVANCED_FILTERS_APPLY: 'ADVANCED_FILTERS_APPLY',
  CJM_ONBOARDING_STEP_REACHED: 'CJM_ONBOARDING_STEP_REACHED',
  CJM_ONBOARDING_SKIP_ONBOARDING_SHEET_VISIBLE: 'CJM_ONBOARDING_SKIP_ONBOARDING_SHEET_VISIBLE',
  CJM_ONBOARDING_SKIP_ONBOARDING_SHEET_SKIP_CLICK: 'CJM_ONBOARDING_SKIP_ONBOARDING_SHEET_SKIP_CLICK',
  CJM_ONBOARDING_SKIP_ONBOARDING_SHEET_CONTINUE_CLICK: 'CJM_ONBOARDING_SKIP_ONBOARDING_SHEET_CONTINUE_CLICK',
  HOMESCR_BTN_PLAY_WITH_FRIENDS: 'HOMESCR_BTN_PLAY_WITH_FRIENDS',
  FRIENDS_GAMES_BTN_CURRENT: 'FRIENDS_GAMES_BTN_CURRENT',
  FRIENDS_GAMES_BTN_PAST: 'FRIENDS_GAMES_BTN_PAST',
  FRIENDS_GAMES_BTN_MINE: 'FRIENDS_GAMES_BTN_MINE',
  FRIENDS_GAMES_BTN_INVITED: 'FRIENDS_GAMES_BTN_INVITED',
  FRIENDS_GAMES_BTN_BACK: 'FRIENDS_GAMES_BTN_BACK',
  PVP_GAMES_BTN_BACK: 'PVP_GAMES_BTN_BACK',
  FRIENDS_GAMES_BANNER_CLICK: 'FRIENDS_GAMES_BANNER_CLICK',
  MAINSCR_LEADERBOARDS_SEE_ALL: 'MAINSCR_LEADERBOARDS_SEE_ALL',
  IDO_SCREEN_OPENED: 'IDO_SCREEN_OPENED',
  IDO_SCREEN_BTN_CLICK: 'IDO_SCREEN_BTN_CLICK',
  WINLINE_BANNER_CLICK: 'WINLINE_BANNER_CLICK',
  OKX_BANNER_CLICK: 'OKX_BANNER_CLICK',
};
