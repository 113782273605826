type TMappedByDay<T> = {
  [day: string]: Array<T>;
};

type TDateFormattingFunction = (d: Date) => string;

const formatDateESPA = (d: Date) => {
  return d.toLocaleDateString('es-PA'); // для es-PA возвращает строку вида mm/dd/yyyy
};

export const mapByDay = <T>(
  items: Array<any>,
  propertyName: string,
  formatFunction: TDateFormattingFunction = formatDateESPA,
  getProperyValue?: (item: any) => string,
): TMappedByDay<T> => {
  const mapped: TMappedByDay<T> = {};

  items?.forEach((item) => {
    const itemsClone = { ...item };
    if (getProperyValue) {
      itemsClone[propertyName] = getProperyValue(itemsClone);
    }
    const d = new Date(itemsClone[propertyName]);
    const day = formatFunction(d);
    if (mapped[day]) {
      mapped[day].push(itemsClone);
    } else {
      mapped[day] = [itemsClone];
    }
  });

  return mapped;
};
