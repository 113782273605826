import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { initAmplitude } from '@Utils/amplitude';
import { setupTelegramWindowObject } from '@Utils/telegram';
import { ContextInterLayer } from '@Global/store';
import { GenericApolloProvider } from '@Apollo/index';
import { App } from './App';
import initI18n from './i18n/config';
import './index.css';
import { store } from './store/redux';
import './tailwind-input.css';
import { addMatchMediaSupport } from './utils/addMatchMediaSupport';
import { initAdsGram } from './utils/adsgram/initAdsGram';
import './variables.css';

global.Buffer = global.Buffer || require('buffer').Buffer;
window.Telegram.WebApp.expand();

setupTelegramWindowObject();
initI18n();
initAmplitude();
initAdsGram();
addMatchMediaSupport();

/** прослойка конфигурации (config entry point) */
const ConfigurationInterLayer: React.FC<React.PropsWithChildren> = ({ children }: React.PropsWithChildren) => {
  return <GenericApolloProvider>{children}</GenericApolloProvider>;
};

/** инициалзиатор */
const Fanton = () => {
  return (
    <React.StrictMode>
      <ConfigurationInterLayer>
        <ContextInterLayer>
          <Suspense fallback={null}>
            <Provider store={store}>
              <App />
            </Provider>
          </Suspense>
        </ContextInterLayer>
      </ConfigurationInterLayer>
    </React.StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<Fanton />);
